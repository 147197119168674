import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import Meta from "../../componets/Meta";

const ContactUs = () => {

  return (
    <div>
    <Container className="ContactUs" fluid>
      <Row style={{ paddingTop: "2%", paddingBottom: "3%" }}>
        <Col sm={12} md={12} lg={6}>
        <iframe
            class="responsive-iframe"
            id="gmap_canvassss"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3608.4692406767554!2d51.52189179999999!3d25.254795800000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e45cf5cc4d8e4c5%3A0x7527406e1f190c81!2sOurshopee%20Qatar!5e0!3m2!1sen!2sae!4v1706788824134!5m2!1sen!2sae"
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
          ></iframe>
        </Col>

        <Col lg={1} sm={12} md={12}></Col>

        <Col lg={5}>
          <Row className="contactfill">
            <h2 className="cnt-info"> Contact info </h2>
            <div>
              <Row style={{ paddingTop: '3%'}}>
                <Col md={2} sm={2} xs={2}>
                  <p> Phone </p>
                </Col>
                <Col md={1} sm={1} xs={1}>
                  <p>:</p>
                </Col>
                <Col md={9} sm={9} xs={9}>
                  <p>+974 4 4625777 </p>
                </Col>
              </Row>
              <Row>
                <Col md={2} sm={2} xs={2}>
                  <p> Mail </p>
                </Col>
                <Col md={1} sm={1} xs={1}>
                  <p>:</p>
                </Col>
                <Col md={9} sm={9} xs={9}>
                  <p> support@ourshopee.com </p>
                </Col>
              </Row>
              <Row>
                <Col md={2} sm={2} xs={2}>
                  <p> Address </p>
                </Col>
                <Col md={1} sm={1} xs={1}>
                  <p>:</p>
                </Col>
                <Col md={9} sm={9} xs={9}>
                  <p>
                    Ourshopee Office | New Salata Complex D Ring Road | Street No. 250  Zone No. 40 |
                    Doha | Qatar
                  </p>
                </Col>
              </Row>
            </div>
          </Row>
          <h2 className="cnt-info">Get It On</h2>
          <div className="Androidimg">
            <img src="Assets/Android_Appstore.png" alt="" className="g-play"/>
            <img
              style={{ paddingLeft: "5%" }}
              src="Assets/online-shopping.png"
              alt="" className="app-store"
            />
          </div>
        </Col>
      </Row>
    </Container>
    </div>
  );
};

export default ContactUs;

import React, { useEffect, useContext } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { GlobalCart } from "../App";
import jwt from "jwt-decode";
import call_apis from "../services/Apis";

const AddToCartAPI = ({ cartDetail ,cartClick,setCartClick}) => {
  const { getCartData, userId, setcarterrMsg} = useContext(GlobalCart)
  const cookies = new Cookies();
  var userData = {};
  var dataLayer = {};

  const getUserdata = () => {
    if (cookies.get("oscad") === undefined) {
      userData = {
        product_id: cartDetail.id,
        ip_address: 0,
        user_id: userId,
        quantity: cartDetail.quantity,
      };
    } else {
      userData = {
        product_id: cartDetail.id,
        ip_address: cookies.get("oscad"),
        user_id: userId,
        quantity: cartDetail.quantity,
      };
    }
    addTocartFunc(userData)
  }

  const removeAnimation = () => {
    document.getElementById("shopCart").style.animation = "unset"
  }


  //Adding datalayer
call_apis.getProductDataId(userData.product_id)
.then(dataLayerResponse => {
  dataLayer = dataLayerResponse.data.data.product[0];

window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
window.dataLayer.push({
event: "add_to_cart",
ecommerce:{
  currency: "QAR",
  value: dataLayer.display_price,
  items: [
  {
    item_id: '"'+ dataLayer.sku + '"',
    item_name: '"'+ dataLayer.name + '"',
    index: 0,
    item_brand: '"'+dataLayer.brand+'"',
    item_category: '"'+dataLayer.category_name+'"',
    item_category2: '"'+dataLayer.subcategory_name+'"',
    item_category3: '"'+dataLayer.sub_sub_category_name+'"',
    price: dataLayer.display_price,
    quantity: dataLayer.quantity
  }
  ]
}
});
})
.catch(error => {
  console.error('Error fetching product data:', error);
});

//End of adding datalayer




  const addTocartFunc = (userData, e) => {
    // e.preventDefault()
    axios
      .post("/api/AddToCart", userData)
      .then((response) => {
        var user_id = 0
        if(cookies.get("jwt_token") !== undefined){
          var decoded = jwt(cookies.get("jwt_token"));
          user_id = decoded.user_id;
        }

        cookies.set('oscad', response.data.data.ip_address, {
          httpOnly: false,
          path: '/',
          maxAge: 60*60*24*5
        });

        if (response.data.data.msg == undefined) {
          setcarterrMsg("")
        } else {
          setcarterrMsg(response.data.data.msg)
        }
        //console.log("addTocartFunc",userId)
        getCartData(user_id);
        setCartClick(false);

      })
      .catch((err) => {
        console.log("err",err);
      });

  };

  useEffect(() => {
    getUserdata()
  }, [])

  return <div></div>;
};

export default AddToCartAPI;
